import { render, staticRenderFns } from "./ExtensionPeriod.vue?vue&type=template&id=356b9501&scoped=true"
import script from "./ExtensionPeriod.vue?vue&type=script&lang=js"
export * from "./ExtensionPeriod.vue?vue&type=script&lang=js"
import style0 from "./ExtensionPeriod.vue?vue&type=style&index=0&id=356b9501&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "356b9501",
  null
  
)

export default component.exports