import { render, staticRenderFns } from "./WorkWithStaff.vue?vue&type=template&id=45968f1e&scoped=true"
import script from "./WorkWithStaff.vue?vue&type=script&lang=js"
export * from "./WorkWithStaff.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45968f1e",
  null
  
)

export default component.exports